<template lang="pug">
	ValidationObserver(v-slot='{ invalid, validated, passes, validate }')
		v-overlay(:value="show" opacity='0.8' z-index='6')
			v-progress-circular(indeterminate color="primary")
		v-card
			v-tabs.filter-tab(v-model='tab' color='#fff' hide-slider)
				v-tab(:ripple='false') {{$t('charts.everyDay')}}
			v-tabs-items(v-model='tab')
				v-tab-item
					v-card.pa-4.pb-0.filter-wrap
						v-row
							v-col.px-3.py-0(cols='12' sm='8' md='6' lg='3')
								DaliyPicker(@emitupdateDate='updateDate')
			v-card(v-resize='onResize').pt-0.pa-4.pb-4.filter-wrap
				v-row.justify-end.exportBtnUpper
					v-col.pa-4(v-if="windowSize.x <= 599")
					v-col.text-right.pa-0(v-if="windowSize.x > 599")
						div(@click="showhelperText")
							ExportBtn(:exportResult='getBankAccountungObj' @emitexportData='exportXlsxFile')
							span(:class="{ show: helperShow }").ml-auto.mr-7.helperText {{$t('common.helpText')}}
				MainSelectItem(:currencyStatus='currencyStatus' @emitDomainValue='updateDomainValue')
				v-row.justify-center
					v-col(cols='12' sm='8' md='6' lg='3')
						v-btn.searchBtn.defult-btn-color(@click='passes(send)' dark rounded style='width:100%; height: 55px;') {{$t('common.search')}}
				v-card.pa-4.filter-wrap.div-w
					v-col.py-0.fixBar.searchDate.justify-center(cols='12' md='6' lg='5' @click='goSearch')
						v-row.py-1
							v-col.ml-5.mr-auto.py-1.px-3(cols="auto")
								span.sub-title  {{searchDates[0]}} ～ {{searchDates[1]}}
							v-col.mr-2.py-1.px-3(cols="auto")
								v-img.researchImg(max-width="25px" :src='imgSrc')
				v-row.justify-center(v-if='basic.items.length !== 0')
							v-col.pa-0(cols='12')
								v-data-table#dataTable.elevation-1.dataTableWrap(:headers='basic.headers' :items='basic.items' :disable-pagination='true' :footer-props="{ disablePagination: true }" :disable-sort='true')
									template(v-slot:body="{ items }")
										tbody
											tr(v-for="(item, index) in items" :key="index")
												td(style="min-width:170px;width:170px;max-width:170px" ref='tableDate') {{item.date}}
												td(style="min-width:170px;width:170px;max-width:170px" ref='bankName') {{item.bankName}}
												td.text-right(style="min-width:150px;width:150px;max-width:150px") {{item.cardId}}
												td(style="min-width:190px;width:190px;max-width:190px") {{item.actName}}
												td.text-right(style="min-width:100px;width:100px;max-width:100px") {{item.dpstAmt}}
												td.text-right(style="min-width:100px;width:100px;max-width:100px") {{item.dpstCount}}
</template>

<script>
import _ from 'lodash';
import statusCode from '@/assets/constant/statusCode'; 
import { getBankAccountung } from '@/api/General';
import { mapActions, mapGetters } from 'vuex';
import { commaFormatter, toThousandslsFilter } from '@/util/format';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import DaliyPicker from '@/components/DatePicker/DaliyPicker';
import ExportBtn from '@/components/ExportBtn';
import MainSelectItem from '@/components/MainSelectItemOCMS';
import { exportXlsx } from '@/util/xlsx';

export default {
	components: {
		ValidationObserver,
		ValidationProvider,
		DaliyPicker,
		ExportBtn,
		MainSelectItem
	},
	data() {
		return {
			dialog: false,
			show: false,
			loading: false,
			windowSize: {
				x: 0,
			},
			helperShow: false,
			currencyStatus: false,
			basic: {
				headers: [
					{
						text: 'Date',
						align: 'start',
						value: 'date',
					},
					{ text: 'Bank Name', value: 'bankName'},
					{ text: 'Card Number', align: 'right', value: 'cardId' },
					{ text: 'Account Name', value: 'actName' },
					{ text: 'Dpst. Amount', align: 'right', value: 'dpstAmt' },
					{ text: 'Dpst. Count', align: 'right', value: 'dpstCount' },
				],
				items: []
			},
			getBankAccountungObj: {},
			dates: [],
			searchDates: [],
			value: [],
			domainMap: {},
			tab: null,
			imgSrc: require('@/assets/image/research.png'),
		};
	},
	mounted() {
		this.initData();
		this.onResize();
	},
	methods: {
		...mapActions(['setDialogShow', 'setStatusDialog']),
		initData() {
			this.$emit('emitsavePath', this.$route.path);
		},
		onResize () {
			this.windowSize = { x: window.innerWidth};
		},
		smoothDown(){
			$('html, body').animate({
				scrollTop: $('.searchBtn').offset().top
			}, 500);
		},
		async send() {
			this.allClean();
			this.show = !this.show;
			this.loading = !this.loading;
			try {
				let domain = [];
				for (let i of this.value) {
					if (this.domainMap[i].indexOf('-')) {
						let tmpJ = this.domainMap[i].split('-');
						domain.push(tmpJ[0]);
					} else {
						domain.push(this.domainMap[i]);
					}
				}
				const reqData = {
					startDate: this.$moment(this.dates[0]).format('YYYY-MM-DD'),
					endDate: this.$moment(this.dates[1]).format('YYYY-MM-DD'),
					domain
				};
				this.searchDates = [reqData.startDate,reqData.endDate];
				const res = await getBankAccountung(reqData);
				if (res.status === statusCode.STATUS_OK) {
					const result = res.result.res;
					this.getBankAccountungObj = JSON.parse(JSON.stringify(result));
					const accountungAfterROE = result.ctx_daily;
					accountungAfterROE.sort(
						function(a, b) {     
							if(a.site_id === b.site_id && a.dim_ptt === b.dim_ptt && a.bank_name === b.bank_name){
								return a.account_card_id.localeCompare(b.account_card_id);
							}	
							if(a.site_id === b.site_id && a.dim_ptt === b.dim_ptt){
								return a.bank_name.localeCompare(b.bank_name);
							}					     
							if (a.site_id === b.site_id) {
								return a.dim_ptt > b.dim_ptt ? 1 : -1;
							}					
							return  a.site_id.localeCompare(b.site_id);
						});
					await this.dataTable(accountungAfterROE);
					// this.mergeDateCells();
					// this.mergeBankCells();
					this.smoothDown();
					this.tab = 0;
				} else {
					this.allClean();
					let noMatchData = this.$t('error.noMatchData');
					this.errorDialogStatus(noMatchData);
				}
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log('e', e);
				this.allClean();
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
			this.show = !this.show;
			this.loading = !this.loading;
		},
		dataTable(accountung) {
			const cardId = [];
			const actName = [];
			const bankName = [];
			const cyId = [];
			const dateTime = [];
			const dpstAmt = [];
			const dpstCount = [];
			const siteId = [];
			for(let i = 0 ;i < accountung.length; i++){
				cardId.push(accountung[i].account_card_id);
				actName.push(accountung[i].account_name);
				bankName.push(accountung[i].bank_name);
				cyId.push(accountung[i].cy_id);
				dateTime.push(accountung[i].dim_ptt);
				dpstAmt.push(accountung[i].dpst_amt);
				dpstCount.push(accountung[i].dpst_count);
				siteId.push(accountung[i].site_id);
			}			
			for (let i = 0; i < dateTime.length; i += 1) {
				var basicObj = {};							
				basicObj['date'] = dateTime[i];
				basicObj['bankName'] = bankName[i];
				basicObj['cardId'] = cardId[i];
				basicObj['actName'] = actName[i];
				basicObj['dpstAmt'] = toThousandslsFilter(dpstAmt[i]);
				basicObj['dpstCount'] = toThousandslsFilter(dpstCount[i]);
				let basicObj2 = Object.assign({}, basicObj);
				this.basic.items.push(basicObj2);
			}
			// this.$nextTick(() => {
			// 	this.mergeDateCells();
			// 	this.mergeBankCells();
			// });	
		},
		async exportXlsxFile(){
			try {
				let result = this.getBankAccountungObj;
				await exportXlsx('d', this.searchDates, result);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log('e', e);
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
		},
		mergeDateCells(){
			var changeDate = 0;			
			this.$refs.dataTable[changeDate].rowSpan = 1;
			for(let i = 1; i < this.$refs.dataTable.length; i++){
				if(this.$refs.dataTable[i].innerText === this.$refs.dataTable[i-1].innerText){
					this.$refs.dataTable[i].style.display='none';
					this.$refs.dataTable[changeDate].rowSpan += 1 ;
				}
				else {
					changeDate = i;			
				}
			}		
		},
		mergeBankCells(){
			var changeBank = 0;			
			this.$refs.bankName[changeBank].rowSpan = 1;
			for(let i = 1; i < this.$refs.bankName.length; i++){
				if(this.$refs.bankName[i].innerText === this.$refs.bankName[i-1].innerText){
					this.$refs.bankName[i].style.display='none';
					this.$refs.bankName[changeBank].rowSpan += 1 ;
				}
				else {
					changeBank = i;			
				}
			}	
		},
		allClean() {
			this.basic.items = [];
		},	
		toInt(data) {
			return _.toInteger(data.replace(/,/g, ''));
		},
		centNumToInteger(number) {
			return commaFormatter(number);
		},
		updateDomainValue(val, val2){
			this.value = val;
			this.domainMap = val2;
		},
		updateDate(val) {
			this.dates = val;
		},
		goSearch() {
			$('body,html').animate({scrollTop:0},800);
		},
		showhelperText(){
			if(Object.keys(this.getBankAccountungObj).length=== 0){
				this.helperShow = true;
			}
		},
		errorDialogStatus(notifyMsg) {
			let errorTitle = this.$t('error.errorTitle');
			const errorMsg = {
				statusMessage: errorTitle,
				statusSrc: require('@/assets/image/error_icon.png'),
				statusText: notifyMsg
			};
			let stringErrorMsg = JSON.stringify(errorMsg);
			this.setDialogShow(true);
			this.setStatusDialog(stringErrorMsg);
		}
	},
	watch: {
		tab: function() {
			$(function() {
				$('.fixBar').removeClass('dateFixBar');
				$(window).scroll(function() {
					let scrollVal = $(this).scrollTop();
					let adscrtop = $('.div-w').offset().top;

					if (scrollVal + 44 > adscrtop) {
						$('.fixBar').addClass('dateFixBar');
					} else {
						$('.fixBar').removeClass('dateFixBar');
					}
				});
			});
		},
		getBankAccountungObj: function() {
			if(Object.keys(this.getBankAccountungObj).length!== 0){
				this.helperShow = false;
			}
		},
	},
	computed: {
		...mapGetters(['getDialogShow', 'getStatusDialog']),
	},
	created() {
		this.allClean();
	}
};
</script>
